<template>
  <v-col class="ml-1 pa-6">
    <v-row>
      <v-select v-model="verified"
                :items="[{text: '게시', value: true}, {text: '게시중단', value: false}]"
                item-text="text"
                item-value="value"
                label="가맹점 인증 상태"
                height="40px"
                hide-details
                outlined solo flat
                class="mr-2"></v-select>
      <v-select v-model="naver"
                :items="[{text: '결제내역없음', value: -2}, {text: '광고게시예정', value: -1}, {text: '게시중', value: 0}, {text: '일시중지', value: 1}, {text: '광고 종료', value: 2}]"
                item-text="text"
                item-value="value"
                label="네이버 게시 상태"
                height="40px"
                hide-details
                outlined solo flat
                class="mr-2"></v-select>
      <v-select v-model="insta"
                :items="[{text: '결제내역없음', value: -2}, {text: '광고게시예정', value: -1}, {text: '게시중', value: 0}, {text: '일시중지', value: 1}, {text: '광고 종료', value: 2}]"
                item-text="text"
                item-value="value"
                label="인스타그램 게시 상태"
                height="40px"
                hide-details
                outlined solo flat></v-select>
    </v-row>
    <v-btn class="rouge white-two--text" @click="save">저장</v-btn>
  </v-col>
</template>

<script>
  import userMixin from '@/components/shared/userMixin'
  import companyMixin from '@/components/shared/companyMixin'

  export default {
    name: "Admin",
    mixins: [userMixin, companyMixin],
    data() {
      return {
        verified: (this.company || {}).verified,
        naver: ((this.company || {}).naver || {}).available,
        insta: ((this.company || {}).insta || {}).available
      }
    },
    watch: {
      company(value) {
        this.verified = (value || {}).verified;
        this.naver = ((value || {}).naver || {}).available;
        this.insta = ((value || {}).insta || {}).available;
      }
    },
    methods: {
      save() {
        let data = {
          verified : this.verified
        };
        if(this.company.naver) {
          data.naver = this.company.naver;
          data.naver.available = this.naver;
        }
        if(this.company.insta) {
          data.insta = this.company.insta;
          data.insta.available = this.insta;
        }
        this.axios.put('/company3?id=' +  (this.company.id || this.company.rKey), data).then(() => {
          this.$store.dispatch('getCompanyInfo', {email: this.user.email});
        }).catch((err) => {
          console.log(err);
        });
      }
    }
  }
</script>

<style scoped>

</style>